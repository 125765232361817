// const React = require('react')
// const { CartProvider } = require('./src/context/cart')

// exports.wrapRootElement = ({ element }) => {
//   return <CartProvider>{element}</CartProvider>
// }

import React from "react"
import { RecoilRoot } from "recoil"
import "./src/assets/css/layout.css"

export const wrapRootElement = ({ element, props }) => {
  return <RecoilRoot {...props}>{element}</RecoilRoot>
}

export const onServiceWorkerUpdateReady = () => {
  if (
    window.confirm(
      "Express riders  has been updated. Do you wish to reload the app to get the new data?"
    )
  ) {
    window.location.reload(true)
  }
}
